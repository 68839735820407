import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import Button from './UI/Button'
import { useTimeoutFn } from 'react-use'
import "vanilla-cookieconsent/dist/cookieconsent.css"
import * as CookieConsent from "vanilla-cookieconsent"

const CAT_NECESSARY = 'necessary'
const CAT_ANALYTICS = 'analytics'
const CAT_ADVERTISEMENT = 'advertisement'
const CAT_FUNCTIONALITY = 'functionality'
const CAT_SECURITY = 'security'

const SERVICE_AD_STORAGE = 'ad_storage'
const SERVICE_AD_USER_DATA = 'ad_user_data'
const SERVICE_AD_PERSONALIZATION = 'ad_personalization'
const SERVICE_ANALYTICS_STORAGE = 'analytics_storage'
const SERVICE_FUNCTIONALITY_STORAGE = 'functionality_storage'
const SERVICE_PERSONALIZATION_STORAGE = 'personalization_storage'
const SERVICE_SECURITY_STORAGE = 'security_storage'

function updateGtagConsent() {
  gtag('consent', 'update', {
        [SERVICE_ANALYTICS_STORAGE]: CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS) ? 'granted' : 'denied',
        [SERVICE_AD_STORAGE]: CookieConsent.acceptedService(SERVICE_AD_STORAGE, CAT_ADVERTISEMENT) ? 'granted' : 'denied',
        [SERVICE_AD_USER_DATA]: CookieConsent.acceptedService(SERVICE_AD_USER_DATA, CAT_ADVERTISEMENT) ? 'granted' : 'denied',
        [SERVICE_AD_PERSONALIZATION]: CookieConsent.acceptedService(SERVICE_AD_PERSONALIZATION, CAT_ADVERTISEMENT) ? 'granted' : 'denied',
        [SERVICE_FUNCTIONALITY_STORAGE]: CookieConsent.acceptedService(SERVICE_FUNCTIONALITY_STORAGE, CAT_FUNCTIONALITY) ? 'granted' : 'denied',
        [SERVICE_PERSONALIZATION_STORAGE]: CookieConsent.acceptedService(SERVICE_PERSONALIZATION_STORAGE, CAT_FUNCTIONALITY) ? 'granted' : 'denied',
        [SERVICE_SECURITY_STORAGE]: CookieConsent.acceptedService(SERVICE_SECURITY_STORAGE, CAT_SECURITY) ? 'granted' : 'denied',
    })
}

export default function CookieBanner({ locale }) {
  // const [wizitoCookie, setWizitoCookie] = useState(true)
  // useTimeoutFn(() => setWizitoCookie(Cookies.get('wizitocookie')), 2000)

  useEffect(() => {
    gtag('consent', 'default', {
      [SERVICE_AD_STORAGE]: 'denied',
      [SERVICE_AD_USER_DATA]: 'denied',
      [SERVICE_AD_PERSONALIZATION]: 'denied',
      [SERVICE_ANALYTICS_STORAGE]: 'denied',
      [SERVICE_FUNCTIONALITY_STORAGE]: 'denied',
      [SERVICE_PERSONALIZATION_STORAGE]: 'denied',
      [SERVICE_SECURITY_STORAGE]: 'denied',
    })
    CookieConsent.run({
      guiOptions: {
        consentModal: {
            layout: 'box wide',
            position: 'bottom center',
            flipButtons: true,
            equalWeightButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: false
        }
    },
    categories: {
      [CAT_NECESSARY]: {
          enabled: true,
          readOnly: true,
      },
      [CAT_ANALYTICS]: {
          autoClear: {
              cookies: [
                  { name: /^_ga/, },
                  { name: '_gid', }
              ]
          },
          services: {
              [SERVICE_ANALYTICS_STORAGE]: {
                  label: 'Enables storage (such as cookies) related to analytics e.g. visit duration.',
              }
          }
      },
      [CAT_ADVERTISEMENT]: {
          services: {
              [SERVICE_AD_STORAGE]: {
                  label: 'Enables storage (such as cookies) related to advertising.',
              },
              [SERVICE_AD_USER_DATA]: {
                  label: 'Sets consent for sending user data related to advertising to Google.',
              },
              [SERVICE_AD_PERSONALIZATION]: {
                  label: 'Sets consent for personalized advertising.',
              },
          }
      },
      [CAT_FUNCTIONALITY]: {
          services: {
              [SERVICE_FUNCTIONALITY_STORAGE]: {
                  label: 'Enables storage that supports the functionality of the website or app e.g. language settings.',
              },
              [SERVICE_PERSONALIZATION_STORAGE]: {
                  label: 'Enables storage related to personalization e.g. video recommendations.',
              },
          }
      },
      [CAT_SECURITY]: {
          services: {
              [SERVICE_SECURITY_STORAGE]: {
                  label: 'Enables storage related to security such as authentication functionality, fraud prevention, and other user protection.',
              },
          }
      }
    },
    language: {
        default: locale,
        translations: {
          en: {
            consentModal: {
                title: 'We use cookies',
                description: 'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.',
                acceptAllBtn: 'Accept all',
                acceptNecessaryBtn: 'Reject all',
                showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
                title: 'Manage cookie preferences',
                acceptAllBtn: 'Accept all',
                acceptNecessaryBtn: 'Reject all',
                savePreferencesBtn: 'Accept current selection',
                closeIconLabel: 'Close modal',
                sections: [
                    {
                        title: "Cookie usage",
                        description: "We use cookies to ensure the basic functionalities of the website and to enhance your online experience."
                    },
                    {
                        title: "Strictly necessary cookies",
                        description: "These cookies are essential for the proper functioning of the website, for example for user authentication.",
                        linkedCategory: CAT_NECESSARY,
                    },
                    {
                        title: "Analytics",
                        description: 'Cookies used for analytics help collect data that allows services to understand how users interact with a particular service. These insights allow services both to improve content and to build better features that improve the user’s experience.',
                        linkedCategory: CAT_ANALYTICS,
                        cookieTable: {
                            headers: {
                                name: "Name",
                                domain: "Service",
                                description: "Description",
                                expiration: "Expiration"
                            },
                            body: [
                                {
                                    name: "_ga",
                                    domain: "Google Analytics",
                                    description: "Cookie set by <a href=\"https://business.safety.google/adscookies/\">Google Analytics</a>",
                                    expiration: "Expires after 12 days"
                                },
                                {
                                    name: "_gid",
                                    domain: "Google Analytics",
                                    description: "Cookie set by <a href=\"https://business.safety.google/adscookies/\">Google Analytics</a>",
                                    expiration: "Session"
                                }
                            ]
                        }
                    },
                    {
                        title: 'Advertising',
                        description: 'Google uses cookies for advertising, including serving and rendering ads, personalizing ads (depending on your ad settings at <a href=\"https://g.co/adsettings\">g.co/adsettings</a>), limiting the number of times an ad is shown to a user, muting ads you have chosen to stop seeing, and measuring the effectiveness of ads.',
                        linkedCategory: CAT_ADVERTISEMENT,
                    },
                    {
                        title: 'Functionality',
                        description: 'Cookies used for functionality allow users to interact with a service or site to access features that are fundamental to that service. Things considered fundamental to the service include preferences like the user’s choice of language, product optimizations that help maintain and improve a service, and maintaining information relating to a user’s session, such as the content of a shopping cart.',
                        linkedCategory: CAT_FUNCTIONALITY,
                    },
                    {
                        title: 'Security',
                        description: 'Cookies used for security authenticate users, prevent fraud, and protect users as they interact with a service.',
                        linkedCategory: CAT_SECURITY,
                    },
                    {
                        title: 'More information',
                        description: 'For any queries in relation to the policy on cookies and your choices, please <a href="mailto:hello@wizito.com">contact us</a>.'
                    }
                ]
            }
        },
        fr: {
          consentModal: {
              title: 'Nous utilisons des cookies',
              description: 'Ce site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ces derniers ne seront installés qu\'après consentement.',
              acceptAllBtn: 'Tout accepter',
              acceptNecessaryBtn: 'Tout refuser',
              showPreferencesBtn: 'Gérer les préférences individuelles'
          },
          preferencesModal: {
              title: 'Gérer les préférences de cookies',
              acceptAllBtn: 'Tout accepter',
              acceptNecessaryBtn: 'Tout refuser',
              savePreferencesBtn: 'Accepter la sélection actuelle',
              closeIconLabel: 'Fermer la fenêtre',
              sections: [
                  {
                      title: "Utilisation des cookies",
                      description: "Nous utilisons des cookies pour assurer les fonctionnalités de base du site web et améliorer votre expérience en ligne."
                  },
                  {
                      title: "Cookies strictement nécessaires",
                      description: "Ces cookies sont essentiels au bon fonctionnement du site web, par exemple pour l'authentification des utilisateurs.",
                      linkedCategory: CAT_NECESSARY,
                  },
                  {
                      title: "Analyse",
                      description: 'Les cookies utilisés à des fins d\'analyse aident à collecter des données qui permettent aux services de comprendre comment les utilisateurs interagissent avec un service particulier. Ces informations permettent aux services d\'améliorer le contenu et de créer de meilleures fonctionnalités qui améliorent l\'expérience de l\'utilisateur.',
                      linkedCategory: CAT_ANALYTICS,
                      cookieTable: {
                          headers: {
                              name: "Nom",
                              domain: "Service",
                              description: "Description",
                              expiration: "Expiration"
                          },
                          body: [
                              {
                                  name: "_ga",
                                  domain: "Google Analytics",
                                  description: "Cookie déposé par <a href=\"https://business.safety.google/adscookies/\">Google Analytics</a>",
                                  expiration: "Expire après 12 jours"
                              },
                              {
                                  name: "_gid",
                                  domain: "Google Analytics",
                                  description: "Cookie déposé par <a href=\"https://business.safety.google/adscookies/\">Google Analytics</a>",
                                  expiration: "Session"
                              }
                          ]
                      }
                  },
                  {
                      title: 'Publicité',
                      description: 'Google utilise des cookies à des fins publicitaires, notamment pour diffuser et afficher des annonces, personnaliser les annonces (en fonction de vos paramètres publicitaires sur <a href=\"https://g.co/adsettings\">g.co/adsettings</a>), limiter le nombre de fois qu\'une annonce est montrée à un utilisateur, masquer les annonces que vous avez choisi de ne plus voir et mesurer l\'efficacité des annonces.',
                      linkedCategory: CAT_ADVERTISEMENT,
                  },
                  {
                      title: 'Fonctionnalité',
                      description: 'Les cookies utilisés pour la fonctionnalité permettent aux utilisateurs d\'interagir avec un service ou un site pour accéder à des fonctionnalités fondamentales pour ce service. Les éléments considérés comme fondamentaux pour le service comprennent les préférences telles que le choix de la langue par l\'utilisateur, les optimisations de produits qui aident à maintenir et à améliorer un service, et la conservation des informations relatives à la session d\'un utilisateur, telles que le contenu d\'un panier d\'achat.',
                      linkedCategory: CAT_FUNCTIONALITY,
                  },
                  {
                      title: 'Sécurité',
                      description: 'Les cookies utilisés pour la sécurité authentifient les utilisateurs, préviennent la fraude et protègent les utilisateurs lorsqu\'ils interagissent avec un service.',
                      linkedCategory: CAT_SECURITY,
                  },
                  {
                      title: 'Plus d\'informations',
                      description: 'Pour toute question concernant la politique en matière de cookies et vos choix, veuillez <a href="mailto:hello@wizito.com">nous contacter</a>.'
                  }
              ]
          }
        }
      }
    },
      onFirstConsent: () => { updateGtagConsent() },
      onConsent: () => { updateGtagConsent() },
      onChange: () => { updateGtagConsent() },
    })
  }, [])

  return null
  if (wizitoCookie) return <div key="cookiebanner" />
  return (
    <div key="cookiebanner" className="fixed bottom-0 md:bottom-4 flex justify-center items-center z-10 w-full">
      <div className="flex max-w-2xl flex-row justify-center items-center text-left py-2 px-4 lg:py-4 lg:px-6 bg-white shadow-xl rounded">
        <div className="text-xs md:text-base leading-tight -mr-6 md:mr-2">
          {locale === 'fr'
            ? 'En cliquant sur « Accepter », vous acceptez le stockage de cookies sur votre appareil pour améliorer la navigation sur le site.'
            : 'By clicking on «Accepts», you accept the storage of cookies on your device to improve site navigation'
          }
        </div>
        <Button onClick={() => setWizitoCookie(() => Cookies.set('wizitocookie', 'true', { expires: 30 }))} className="scale-75 transform origin-right lg:transform-none">
          {locale === 'fr' ? 'Accepter' : 'Accept'}
        </Button>
      </div>
    </div>
  )       
}